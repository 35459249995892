<template>
  <v-container>
    <!-- <div class="text-center">
  <div class="spinner-border loader_temp" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->
    <!-- <div class="fullPageLoaderBox">
      <div class="fullPageLoader">
  
      </div>
    </div> -->
    <!-- <div id="loaderContainer" >
    <div class="loader-temp"></div>
</div> -->
    <v-card outlined elevation="0" class="mb-1">
      <div class="mt-4 mb-0">
        <ItcannerHeader/>
      </div>
    </v-card>
    <v-card outlined elevation="0" class="pt-2">
      <!-- <v-card-title>

        <v-btn icon small @click="$router.push('/')" fab class="info mx-2 mr-5" dark>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <h1 class="display-2 font-weight-medium">Enter Order ID</h1>

        <v-spacer></v-spacer>

        <v-btn icon fab class="info" dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title> -->
      <!-- <v-divider></v-divider> -->
      <div v-if="$route.params.id" class="claim-new-cashback text-center h3 pb-2 mb-5">
      Edit Cashback Claim with Claim ID #{{claimId}}
      </div>
      <div v-else class="claim-new-cashback text-center h3 mb-5">
        Claim new Cashback
      </div>
      <div v-if="success == 0">
      <v-form
       ref="form"
    v-model="valid"
    lazy-validation
    >
        <v-card-text>
          <div class="text-center row" style="place-content: center;">
            <div class="col-md-8 col-11 row p-0">
              <div class="col-md-6 p-0">
                <v-card-title>
                  Brand*
                </v-card-title>
              </div>
              <div class="col-md-6 p-0">
                <v-select
                class="mx-4"
                v-model="formData.brand"
                  :rules="[v => !!v || 'Brand is required']"
                  outlined
                  width="200px"
                  label="Brand"
                  item-text="name"
                  item-value="id"
                  :items="allBrands.data"
                >
                </v-select>
              </div>
            </div>
  
            <div class="col-md-8 col-11 row p-0">
              <div class="col-md-6 p-0">
                <v-card-title>
                  Order ID or number*
                </v-card-title>
              </div>
              <div class="col-md-6 p-0">
                <v-text-field
                class="mx-4"
                v-model="formData.orderid"
                  outlined
                  type="text"
                  :rules="[v => !!v || 'Order ID is required']"
                  label="Order Id"
                  hide-spin-buttons
                ></v-text-field>
              </div>
            </div>

              
            <div class="col-md-8 col-11 row p-0">
              <div class="col-md-6 p-0">
                <v-card-title>
                  Order Date
                </v-card-title>
              </div>
              <div class="col-md-6 p-0">
                <v-text-field 
                class="mx-4"
                type="date" 
                v-model="formData.orderDate"
                outlined
                >
                </v-text-field>
              </div>
            </div>
  
            <div class="col-md-8 col-11 row p-0">
              <div class="col-md-6 p-0">
                <v-card-title style="word-break: inherit;" class="text-left">
                  Attach a proof of your order confirmation*
                </v-card-title>
              </div>
              <div class="col-md-6 p-0">
                <!-- <input type="file" @change="uplodeImage"> -->
                <v-file-input
                id="fileName"
                type="file"
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                v-model="formData.files"
                class="mx-4"
                  color="deep-purple accent-4"
                  counter
                  :rules="filerules"
                  label="File input"
                  multiple
                  placeholder="Select your files"
                  append-icon="mdi-file"
                  outlined
                  @change="uploadFile"
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                      {{ text }}
                    </v-chip>
      
                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>

                  
              </div>
            </div>
            <div class="col-md-8 col-11 row p-0">
              <div  v-for="(img, key) in editFiles">
              <div v-show="img.show">
                <div class="claim-pdf-icon" v-if="img.file_ext == 'jpg' || img.file_ext == 'jpeg' || img.file_ext == 'png'">
                  <img style="width: 100px;height: 100px;"  class="img-pre" :src="img.file_url">
                </div>
                <div class="claim-pdf-icon" v-else-if="img.file_ext == 'pdf'">
                  <v-icon color="" style="font-size:100px"> mdi-file-pdf-box </v-icon>
                </div>
                <div class="claim-pdf-icon" v-else>
                  <v-icon color="" style="font-size:100px"> mdi-file </v-icon>
                </div>
                <v-btn class="claim-file-delete" @click="removeImage(img,key)"><v-icon >mdi-delete</v-icon></v-btn>
              </div>
            </div>
            </div>
            <div class="col-md-8 col-11 row p-0 text-center" v-if="showError">
              <v-card-text class="error-message">
                {{error}}
              </v-card-text>
            </div>
  
  
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn height="50" :disabled=" claimLoader" @click="claimNewCashback"  color="success" class="mx-auto" rounded width="200">
            <div class="loader1" v-if="claimLoader"></div>
            <div v-else>
              <div v-if="$route.params.id">
                Edit my cashback
              </div>
              <div v-else>
                Claim my cashback
              </div>
            </div>
          </v-btn>
        </v-card-actions>
    </v-form>
      </div>
      <div v-if="success == 1" class="text-center">
      <div class="success-message" v-if="$route.params.id">
        Awesome! Your claim #{{claimId}} has been edited successfuly<br>
        Hold tight! We will update your wallet shortly.
      </div>
      <div class="success-message" v-else>
        Awesome! Your new claim #{{claimId}} has been placed successfuly<br>
        Hold tight! We will update your wallet shortly.
      </div>
        <br>
        <div class="terms-and-condition">
          Read our Terms and Conditions for Withdrawal requests here.
        </div>
        <br>
        <v-card-actions>
          <v-btn height="50" @click="$router.push('/applicants-my-claims')" depressed color="success" class="mx-auto" rounded width="200">
            Back
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    
  </v-container>
</template>

<script>
import axios from 'axios';
import ItcannerHeader from './ItcannerHeader.vue';
export default {
  components:{
    ItcannerHeader
  },
  data: () => ({
    claimId:null,
    valid: true,
    claimLoader:false,
    error:'',
    showError:false,
    success: 0,
    orderId: '',
    filerules: [
        value => !!value || value.size < 1000000 || 'File is required of size less than 10 MB!',
      ],
    allBrands:[],
      files: [],
      editFiles: [],
      deleteEditFiles: [],
    formData:{
      files: [],
      brand:'',
      orderid:'',
      orderDate:'',
    },
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => (value && value.length <= 5) || "Max 5 characters",
    },
  }),
  async created(){
    this.formData.files = [];
    this.formData.brand = '';
    this.formData.orderid = '';
    this.formData.orderDate = '';
    this.brandsData();
    if(this.$route.params.id){
      this.$store.dispatch("setLoader");
      console.log(this.$route.params.id);
      await axios.get('edit-claim',{
                    params: {
                      id:this.$route.params.id,
                    }
                  })
                  .then(response => {
                    this.formData.orderid = response.data.data.claim.order_id;
                    this.formData.brand = response.data.data.claim.brand_id;
                    this.formData.orderDate = response.data.data.claim.order_at;
                    this.editFiles = response.data.data.claim_file; 
                    this.claimId = response.data.data.claim.claim_id;
                    console.log(response.data.data.claim_file);
                  });
    this.$store.dispatch("hideLoader");

    }
  },
  methods:{
    back(){
      this.success = 0;
      
    },
    uploadFile(e){
      
          var type = [];
          type.push('image/png');
          type.push('image/jpg');
          type.push('application/pdf');

            if (type.includes(e[0]['type'])){
              return true;
            }else
            {
              this.formData.files = [];
              this.editFiles = [];
              // document.getElementsByClassName('claim-file-delete').click();
              
              alert('Only PNG, JPG and PDF file is allowed');
            }
},
    async claimNewCashback(){
      if(this.$refs.form.validate()){
        let formData  = new FormData;
        formData.append("brand",this.formData.brand);
        formData.append("order_id",this.formData.orderid);
          this.formData.files.forEach(file => {
            formData.append("files[]",file);
          })
            console.log(this.files);
        formData.append("order_date",this.formData.orderDate);
          console.log(formData);
  
  
        this.claimLoader = true;
        var headers = {'Content-Type': 'multipart/form-data'}
        if(this.$route.params.id){
           this.deleteEditFiles.forEach(id => {
            formData.append("deleteFilesIds[]",id);
          })
        formData.append("id",this.$route.params.id);
          await axios.post('update-claim',formData)
                  .then(response => {
                    console.log(response);
                    if(response.data.status){
                      this.orderId = response.data.data.order_id;
                      this.claimId = response.data.data.claim_id;
                      this.success = 1;
                      this.claimLoader = false;
                    }
                    else{
                      this.error = response.data.error_msg;
                      this.showError = true;
                      this.claimLoader = false;
                    }
                  })
                      this.claimLoader = false;

        }
        else{
          await axios.post('new-claim',formData)
                  .then(response => {
                    // console.log(response);
                    if(response.data.status){
                      this.orderId = response.data.data.order_id;
                      this.claimId = response.data.data.claim_id;
                      this.success = 1;
                      this.claimLoader = false;
                    }
                    else{
                      this.error = response.data.error_msg;
                      this.showError = true;
                      this.claimLoader = false;
                    }
                  })
                      this.claimLoader = false;
        }
      }
    },
    async brandsData(){
      await axios.get('brand/index?client_id=1')
      .then(response => {
        if(response.status){
          this.allBrands = response.data;
        }
      })
    },
    removeImage(img,key){
      this.editFiles[key].show = false;
      this.deleteEditFiles.push(img.id);
      // console.log(this.deleteEditFiles);
    }
  },
};
</script>

<style>
.claim-new-cashback{
    color: #36cdc5 !important;
}
.terms-and-condition{
  font-size: 13px;
}
.success-message{
    font-size: 20px;
    font-weight: bold;
}
</style>
